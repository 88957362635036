// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-move-page-js": () => import("./../src/templates/move-page.js" /* webpackChunkName: "component---src-templates-move-page-js" */),
  "component---src-templates-still-page-js": () => import("./../src/templates/still-page.js" /* webpackChunkName: "component---src-templates-still-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-works-js": () => import("./../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

